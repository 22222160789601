import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import { Button } from '../../components/button'
import { Consumer } from '../../store/createContext'

import { Section } from '../../components/sections'
import { Grid } from '../../components/grid'

import styled from 'styled-components'
import { colors } from '../../styles/colors'

const gridGap = '16px'

const Quote = styled.div`
  p {
    font-style: italic;
    padding: 0 24px;
  }
  h6 {
    color: ${colors.quaternary.default};
    text-align: right;
  }
`

// Large Images
// 0.6684073107

// Small Images
// 0.6927374302

// const Image = styled.img`
//   width: 100%;
//   max-width: 100%;
//   height: auto;
//
//   &:hover {
//     cursor:pointer;
//   }
// `

const Image = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  padding-top: 75%;
  background-image: url("${props => props.src}");
  background-size: cover;
  background-position: center;
  
  &:hover {
    cursor:pointer;
  }
`

const SmallImage = styled(Image)`
  padding-top: 69.27374302%;
`

const ProjectSubgrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gridGap};
`

const PostPagination = styled(Grid)`
  margin-top: 40px;

  a {
    text-decoration: none;
    color: ${props => (props.dark ? '#FFF' : colors.primary.default)};
  }

  a,
  div {
    display: flex;
    align-items: center;
  }

  h6 {
    margin: 0;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  overflow: auto;

  .constrain {
    max-width: 1440px;
    margin: auto;
    z-index: 2;
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${props =>
      props.dark ? 'rgba(0,45,62,0.8)' : 'rgba(255,255,255,0.8)'};
    z-index: 1;
  }

  .close {
    width: 100%;
    text-align: right;
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;

    button {
      appearance: none;
      background: transparent;
      border: none;
    }
  }

  img {
    margin: auto;
    max-height: 80vh;
    max-width: 100%;
    height: auto;
  }
`

const FullHeightImage = styled.div`
  display: flex;
  position: relative;
  height: 100%;
`

const foldm = (r, j) =>
  r.reduce((a, b, i, g) => (!(i % j) ? a.concat([g.slice(i, i + j)]) : a), [])

class CaseStudyTemplate extends Component {
  state = {
    imageModal: false,
    modalImage: '',
  }

  toggleImageModal = image => {
    let scrollTarget = document.getElementsByTagName('html')[0]
    scrollTarget.style = 'overflow: hidden'

    this.setState({
      imageModal: !this.state.imageModal,
      modalImage: this.state.modalImage !== image ? image : '',
    })
  }

  closeImageModal = () => {
    let scrollTarget = document.getElementsByTagName('html')[0]
    scrollTarget.style = 'overflow: visible'

    this.setState({
      imageModal: false,
      modalImage: '',
    })
  }
  render() {
    const { data } = this.props.data.caseStudy
    const { title: siteTitle } = this.props.data.site.siteMetadata

    let images = data.frontmatter.images
    const featuredImage = images.length ? images[0] : null
    images = images.filter(image => image !== featuredImage)
    images = foldm(images, 3)

    const createTags = tags => {
      let tagString = ''

      tags.map((tag, i) => {
        tagString = tagString + tag
        if (tags.length !== i + 1) tagString = tagString + ', '
      })

      return tagString
    }

    const createPostSlug = fileName => {
      return fileName
        .split('/')
        .pop()
        .replace('.md', '')
    }

    return (
      <Consumer>
        {({ dark }) => (
          <Layout location={this.props.location}>
            <Helmet
              htmlAttributes={{ lang: 'en' }}
              meta={[
                { name: 'description', content: data.excerpt },
                {
                  name: 'og:image',
                  content: 'https://madebypioneer.com' + featuredImage,
                },
              ]}
              title={`${data.frontmatter.title} | ${siteTitle}`}
            />
            <Section>
              <Grid gap="20px" offset={1}>
                <Grid desktop={{ columns: '1fr 2fr' }}>
                  <div>
                    <h1 className="h3">{data.frontmatter.title}</h1>
                    <h6>{createTags(data.frontmatter.tags)}</h6>
                    <div dangerouslySetInnerHTML={{ __html: data.html }} />
                    {data.frontmatter.quote.quote && (
                      <Quote>
                        <p>"{data.frontmatter.quote.quote}"</p>
                        {data.frontmatter.quote.name && (
                          <h6>
                            {data.frontmatter.quote.name}
                            {data.frontmatter.quote.title &&
                              `<br />${data.frontmatter.quote.title}`}
                          </h6>
                        )}
                      </Quote>
                    )}
                  </div>
                  {featuredImage && (
                    <FullHeightImage>
                      <Image
                        src={featuredImage}
                        onClick={() => this.toggleImageModal(featuredImage)}
                      />
                    </FullHeightImage>
                  )}
                </Grid>
                {images.length
                  ? images.map((image, i) => {
                      const isEven = i % 2 === 0
                      const FirstImage = image[0] ? image[0] : null
                      const SecondImage = image[1] ? image[1] : null
                      const ThirdImage = image[2] ? image[2] : null

                      return (
                        <Grid
                          key={i}
                          desktop={{
                            columns: isEven ? '2fr 1fr' : '1fr 2fr',
                            gap: gridGap,
                          }}
                        >
                          {isEven && (
                            <FullHeightImage>
                              <Image
                                src={FirstImage}
                                onClick={() =>
                                  this.toggleImageModal(FirstImage)
                                }
                              />
                            </FullHeightImage>
                          )}

                          {((isEven && SecondImage) ||
                            (!isEven && FirstImage)) && (
                            <ProjectSubgrid>
                              <Image
                                src={isEven ? SecondImage : FirstImage}
                                onClick={() =>
                                  this.toggleImageModal(
                                    isEven ? SecondImage : FirstImage
                                  )
                                }
                              />
                              {((isEven && ThirdImage) ||
                                (!isEven && SecondImage)) && (
                                <Image
                                  src={isEven ? ThirdImage : SecondImage}
                                  onClick={() =>
                                    this.toggleImageModal(
                                      isEven ? ThirdImage : SecondImage
                                    )
                                  }
                                />
                              )}
                            </ProjectSubgrid>
                          )}

                          {!isEven && ThirdImage && (
                            <FullHeightImage>
                              <Image
                                src={ThirdImage}
                                onClick={() =>
                                  this.toggleImageModal(ThirdImage)
                                }
                              />
                            </FullHeightImage>
                          )}
                        </Grid>
                      )
                    })
                  : ''}
                <PostPagination
                  gap="20px"
                  columns={'repeat(3, 1fr)'}
                  dark={dark}
                >
                  <div>
                    {this.props.pageContext.previous && (
                      <h6>
                        <Link
                          to={`/work/${createPostSlug(
                            this.props.pageContext.previous.fileAbsolutePath
                          )}`}
                        >
                          <svg
                            width="10px"
                            height="16px"
                            viewBox="0 0 10 16"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{ marginRight: 8 }}
                          >
                            <g
                              id="Home"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                transform="translate(-997.000000, -948.000000)"
                                id="//-PROJECT-//"
                                stroke={colors.primary.default}
                              >
                                <g transform="translate(0.000000, 431.000000)">
                                  <g id="Coastal">
                                    <g
                                      id="Project-Nav"
                                      transform="translate(990.000000, 511.000000)"
                                    >
                                      <g
                                        id="Left-Arrow"
                                        transform="translate(12.000000, 14.000000) rotate(-360.000000) translate(-12.000000, -14.000000) translate(0.000000, 2.000000)"
                                      >
                                        <polyline
                                          id="Path-2"
                                          transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                          points="4.5 15.75 12 8.25 19.5 15.75"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          Previous
                        </Link>
                      </h6>
                    )}
                  </div>
                  <div>
                    {console.log(this.props)}
                    <h6 style={{ margin: '0 auto' }}>
                      <Link to={'/work'} style={{ textAlign: 'center' }}>
                        Back To Work
                      </Link>
                    </h6>
                  </div>
                  <div>
                    {this.props.pageContext.next && (
                      <h6 style={{ marginLeft: 'auto' }}>
                        <Link
                          to={`/work/${createPostSlug(
                            this.props.pageContext.next.fileAbsolutePath
                          )}`}
                        >
                          Next
                          <svg
                            width="10px"
                            height="16px"
                            viewBox="0 0 10 16"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{ marginLeft: 8 }}
                          >
                            <g
                              id="Home"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                transform="translate(-1333.000000, -946.000000)"
                                id="//-PROJECT-//"
                                stroke={colors.primary.default}
                              >
                                <g transform="translate(0.000000, 431.000000)">
                                  <g id="Coastal">
                                    <g
                                      id="Project-Nav"
                                      transform="translate(990.000000, 511.000000)"
                                    >
                                      <g
                                        id="Right-Arrow"
                                        transform="translate(348.000000, 12.000000) rotate(-180.000000) translate(-348.000000, -12.000000) translate(336.000000, 0.000000)"
                                      >
                                        <polyline
                                          id="Path-2"
                                          transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
                                          points="4.5 15.75 12 8.25 19.5 15.75"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </h6>
                    )}
                  </div>
                </PostPagination>
              </Grid>
              {this.state.imageModal && (
                <Modal dark={dark}>
                  <div className="constrain">
                    <div className="close">
                      <button onClick={this.closeImageModal}>
                        <Button>Close</Button>
                      </button>
                    </div>
                    <img src={this.state.modalImage} alt="" />
                  </div>
                </Modal>
              )}
            </Section>
          </Layout>
        )}
      </Consumer>
    )
  }
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    caseStudy: file(
      name: { eq: $slug }
      sourceInstanceName: { eq: "caseStudies" }
    ) {
      data: childMarkdownRemark {
        html
        excerpt
        frontmatter {
          title
          tags
          images
          quote {
            quote
            name
            title
          }
        }
      }
    }
  }
`
